import React from "react";
import {graphql} from "gatsby";
import ContactForm from "../components/contact";
import {GoogleMap} from "../components/google-map";
import PageParallax from "../components/page-parallax";

import Layout from "../components/layout";
import SEO from "../components/seo";

export const query = graphql `
query ContactPageQuery {
    sanityContactPage {
      contactHero {
        ...Image
      }
    }
  }
`;

const ContactPage = ({data}) => (
    <Layout>
        <SEO title="Contact Us" image={data.sanityContactPage.contactHero}/>
        <PageParallax image={data.sanityContactPage.contactHero} title="Contact Us"/>
        <ContactForm/>
        <GoogleMap/>
    </Layout>
);

export default ContactPage;
