import React from "react";
import GoogleMapReact from "google-map-react";
import Icon from "../branding/icon-magnafire-map.png";

function mapOptions() {
    // next props are exposed at maps "Animation", "ControlPosition",
    // "MapTypeControlStyle", "MapTypeId", "NavigationControlStyle",
    // "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem",
    // "DistanceMatrixStatus", "DistanceMatrixElementStatus", "ElevationStatus",
    // "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus", "MaxZoomStatus",
    // "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode",
    // "UnitSystem"
    return {
        fullscreenControl: false,
        styles: [
            {
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "elementType": "labels.text.stroke",
              "stylers": [
                {
                  "color": "#f5f5f5"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#bdbdbd"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "poi.park",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#ffffff"
                }
              ]
            },
            {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#757575"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#dadada"
                }
              ]
            },
            {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#616161"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            },
            {
              "featureType": "transit.line",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#e5e5e5"
                }
              ]
            },
            {
              "featureType": "transit.station",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#eeeeee"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                {
                  "color": "#c9c9c9"
                }
              ]
            },
            {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": [
                {
                  "color": "#9e9e9e"
                }
              ]
            }
          ]
    };
}

const isClient = typeof window !== 'undefined';

export const GoogleMap = () => {
    const lat = 49.274347;
    const lng = -123.0761405;
    return (
        <section id="google-map">
            <div className="map h-96 w-full">
                {isClient && (
                    <GoogleMapReact
                        bootstrapURLKeys={{
                        key: "AIzaSyC6KZot-_DHlSE9UZzJt1I6ksPE3dEA9mw"
                    }}
                        defaultCenter={[lat, lng]}
                        defaultZoom={14}
                        options={mapOptions}
                        >
                        <img src={Icon} lat={lat} lng={lng} className="h-14"/>
                    </GoogleMapReact>
                )}
            </div>
        </section>
    );
}