import React, {createRef} from "react";
import {useStaticQuery, graphql} from "gatsby";
import {NetlifyForm, Honeypot, Recaptcha} from "react-netlify-forms";

const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY

const ContactForm = () => {

    const data = useStaticQuery(graphql `
        query contactText {
            sanityContactPage {
           contactHeaderText1
               contactHeaderText2
          }
      }
      `);

    const headerText1 = data.sanityContactPage.contactHeaderText1
    const headerText2 = data.sanityContactPage.contactHeaderText2

    return (
        <section id="contact-form" class="py-10 px-5 text-black bg-secondary">
            <div class="mx-auto text-center">
                <h1 class="uppercase text-4xl mb-2 font-medium">{headerText1}</h1>
                <h2 class="text-xl mb-10">{headerText2}</h2>
            </div>
            <NetlifyForm
                name="Contact"
                action="/thanks"
                honeypotName="bot-field"
                enableRecaptcha
                onSuccess={(response, context) => {
                     context.formRef.current.reset()
            }}>
                {({handleChange, success, error}) => (
                    <>
                        <Honeypot/> 
                        <Recaptcha siteKey={RECAPTCHA_KEY} invisible/>
                        <div className="container flex mx-auto">
                            <div className="w-full">

                            {success && (
                                <div className="bg-green-600 py-2 mb-3">
                                    <p className="halogen text-sm text-center text-white">Successfully submitted! 🎉</p>
                                </div>
                            )}
                            {error && (
                                <div className="bg-red-600 py-2 mb-3">
                                    <p className="halogen text-sm text-center text-white">There was an error with your submission, please contact us using the following address: darren@magnafiremedia.com</p>
                                </div>
                            )}

                                <div className="mb-4">
                                    <label htmlFor="name" className="block mb-2">Your Name (required)</label>
                                    <input
                                        required={true}
                                        className="form-control bg-dark border-b-2 outline-none border-black w-full h-16 p-3 text-black"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}/>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="email" className="block mb-2">Your Email (required)</label>
                                    <input
                                        required={true}
                                        className="form-control bg-dark border-b-2 outline-none border-black w-full h-16 p-3 text-black"
                                        type="email"
                                        name="email"
                                        onChange={handleChange}/>
                                </div>

                                <div>
                                    <label htmlFor="message" className="block mb-2">Your Message</label>
                                    <textarea
                                        required={true}
                                        className="form-control bg-dark border-b-2 outline-none border-black w-full h-44 p-3 text-black"
                                        name="message"
                                        onChange={handleChange}/>
                                 </div>

                                    <button type="submit" aria-label="button" className="magnaBtn btn mt-4">
                                        <span>Submit</span>
                                    </button>

                            </div>
                        </div>
                    </>
                )}
            </NetlifyForm>
        </section>
    )
}

export default ContactForm;